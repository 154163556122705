import React, { useRef, useState, useEffect } from 'react';
import { put } from 'redux-saga/effects';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

// Components
import Label from '../../components/Label';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomButton from '../../components/CustomButton';
import { AvailabilityButton, ResumeButton } from '../../components/ActionButtons';
import AvailabilityDetails from '../../components/popup/AvailabilityDetails';

// date-fns
import addMinutes from 'date-fns/addMinutes';

// Prime-React Components
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import { InputSwitch } from 'primereact/inputswitch';
import { Sidebar } from 'primereact/sidebar';

// Redux-Actions
import { allInterviewers } from '../../redux/actions/showOptions';
import { editCandidate, getCandidateDetails, addCandidate } from '../../redux/actions/candidates';
import { getInterviewTechnology, getJobDesignationBySearch } from '../../redux/actions/hr';
import { getFilterCommonParamsList } from '../../redux/actions/commonParameter';

// Constants
import {
  INTERVIEW_TYPE,
  BUTTON_TYPES,
  LABEL_TYPES,
  MSG_TYPES,
  TEXTAREA_LIMIT,
  CANDIDATE_SOURCE_TYPE,
  InterviewType,
  RENDER,
  INTERVIEW_CATEGORY,
  Interview_Category,
  InterviewMode,
  INTERVIEW_MODE,
  CANDIDATE_LOCATIONS,
  queryParamsOfFeedbackParams
} from '../../constants/common';
import { INTERVIEW_DURATION, NUMBER_OF_INTERVIEWERS } from '../../constants/dropdownOptions';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { setIsUpdatedSuccess, updateData } from '../../redux/slices/candidates';
import { updateCandidateData } from '../../redux/slices/userDetails';

// Utils
import {
  filterFeedbackParams,
  formatAvailabilityTime,
  imageValidation,
  openResumeLink,
  resumeValidation,
  wordCapitalize
} from '../../utils/common';

// Apis
import { checkEmailMobile } from '../../redux/actions/admin';
import { Badge } from 'primereact/badge';

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/resume`;
const profilePathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/profile`;

const AddEditCandidates = (props) => {
  // Props.
  const { onHide, show, ID, handleView } = props;

  // Redux hooks.
  const dispatch = useDispatch();
  const { candidateDetails, isUpdatedSuccess } = useSelector((state) => state.candidates);
  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);
  const { searchData, interviewTechnologyType } = useSelector((state) => state.hrs);
  const { options, candidates } = useSelector((state) => state);
  const { filterParameters } = useSelector((state) => state.parameter);

  // React useState hooks.
  const [designationOptions, setdesignationOptions] = useState(null);
  const [designationText, setDesignationText] = useState('');
  const [fileName, setFileName] = useState('');
  const [imageName, setImageName] = useState('');
  const [feedbackParamsOptions, setFeedbackParamsOptions] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMinutes(new Date().getMinutes() + 30))
  );
  const [interviewerOptions, setInterviewerOptions] = useState(null);
  const [techSearchText, setTechSearchText] = useState(null);
  const [isFile, setIsFile] = useState('');
  const [isImage, setIsImage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [candidateData, setCandidateData] = useState({
    firstName: '',
    lastName: '',
    gender: 1,
    resumeFilePath: '',
    candidatePhotoPath: '',
    email: '',
    mobile: '',
    secondaryMobile: '',
    yearOfExperience: '',
    monthsOfExperience: '',
    interviewType: '',
    interviewerID: '',
    feedbackParameterIds: [],
    interviewerLimit: NUMBER_OF_INTERVIEWERS[0],
    interviewStartDateTime: '',
    interviewEndDateTime: '',
    duration: 60,
    description: '',
    interviewTechnology: [],
    jobDesignation: '',
    isEdit: true,
    isScheduleInterviewEnabled: false,
    currentCompany: '',
    currentCTC: '',
    candidateLocation: '',
    location: '',
    interviewRoundMode: InterviewMode['Tatvasoft Office'],
    interviewCategory: Interview_Category.Technical,
    candidateSource: CANDIDATE_SOURCE_TYPE[0]?.code || 1,
    referenceEmployeeName: ''
  });

  // React useRef hooks.
  const fileRef = useRef(null);
  const imageRef = useRef(null);

  // Functions.
  // const technologiesID = technologies && technologies.split(',');
  const onTogglePopup = () => setShowPopup((prevState) => !prevState);
  const onDateChange = (value) => setStartDate(value);
  const handleDesignationSearch = () => {
    if (designationText) {
      const sanitizeString = designationText.trim().toString();
      dispatch(getJobDesignationBySearch(sanitizeString));
    }
  };

  useEffect(() => {
    if (!techSearchText) {
      const modifiedTechnologyOptions =
        interviewTechnologyType &&
        interviewTechnologyType.find(
          (technology) => technology.ID === candidateData.interviewTechnology
        );
      setTechSearchText(modifiedTechnologyOptions);
    }
  }, [interviewTechnologyType, candidateData]);

  useEffect(() => {
    dispatch(getInterviewTechnology());
  }, [dispatch]);

  const modifiedOptions = (options) => {
    if (!options?.length) return [];
    return options?.map((opt) => {
      return {
        ...opt,
        name: `${wordCapitalize(opt.FirstName)} ${wordCapitalize(opt.LastName)}`,
        search: `${opt.FirstName} ${opt.LastName} [${opt?.UMap?.map((tech) =>
          wordCapitalize(tech?.MapT?.Technology)
        ).join(', ')}]`,
        code: `${opt.ID}`,
        key: `${opt.ID}`,
        disabled: !opt.IsAvailable,
        isAvailable: opt.IsAvailable
      };
    });

    // let optionArray = [];
    // if (Object.keys(options)?.length) {
    //   Object.keys(options).forEach((key) => {
    //     if (options[key]?.length) {
    //       let dividedOptions = options[key]?.map((opt) => {
    //         return {
    //           ...opt,
    //           name: `${opt.FirstName} ${opt.LastName}`,
    //           value: `${opt.ID}`,
    //           disabled: key === 'Hrs' ? false : !opt.IsAvailable,
    //           isAvailable: key === 'Hrs' ? true : opt.IsAvailable,
    //           key: `${opt.ID}`
    //         };
    //       });
    //       optionArray.push({
    //         label: key,
    //         code: key,
    //         items: dividedOptions
    //       });
    //     }
    //   });
    // }
    // return optionArray;
  };

  useEffect(() => {
    if (!ID) {
      const modifiedInterviewerOptions = modifiedOptions(options);
      setInterviewerOptions(modifiedInterviewerOptions);
    }
  }, [options, ID]);

  useEffect(() => {
    const modifiedDesignationOptions =
      searchData && searchData.length > 0 ? searchData : [{ Designation: MSG_TYPES.NO_RESULT }];
    setdesignationOptions(modifiedDesignationOptions);
  }, [searchData]);

  useEffect(() => {
    if (ID && candidateDetails && Object.keys(candidateDetails).length !== 0) {
      let candidateMobile = candidateDetails.Mobile;
      if (!candidateMobile || (candidateMobile && candidateMobile === 'null')) {
        candidateMobile = '';
      }
      let isLocationFromDropdown = CANDIDATE_LOCATIONS.some(
        (location) => location.code === candidateDetails.Address
      );
      setCandidateData((prevState) => ({
        ...prevState,
        isEdit: false,
        firstName: `${candidateDetails.FirstName}`,
        lastName: `${candidateDetails.LastName}`,
        email: candidateDetails.Email,
        mobile: candidateMobile,
        secondaryMobile: candidateDetails?.SecondaryMobile || '',
        gender: parseInt(candidateDetails.Gender),
        yearOfExperience: candidateDetails.YearsOfExperience,
        monthsOfExperience: candidateDetails.MonthsOfExperience,
        currentCompany: candidateDetails.CurrentCompany || '',
        currentCTC: candidateDetails.CurrentCTC || '',
        candidateLocation: isLocationFromDropdown ? candidateDetails.Address : 'other',
        location: isLocationFromDropdown ? '' : candidateDetails.Address,
        candidateSource: candidateDetails.CandidateSource || '',
        referenceEmployeeName: candidateDetails.EmployeeReferenceName || '',
        interviewTechnology:
          candidateDetails.CandidateTechnology && candidateDetails.CandidateTechnology.length > 0
            ? candidateDetails.CandidateTechnology?.map((tech) => tech.ID)
            : []
      }));
      if (candidateDetails?.ResumeOfCandidate?.FilePath) {
        setIsFile(candidateDetails?.ResumeOfCandidate?.FilePath);
      } else {
        setIsFile('');
      }
      if (candidateDetails?.UserProfile) {
        setIsImage(candidateDetails?.UserProfile);
      } else {
        setIsImage('');
      }
    }
  }, [candidateDetails, ID]);

  const clearData = () => {
    onHide(false);
    document.body.classList.remove('sidebar-open');
    setCandidateData({
      firstName: '',
      lastName: '',
      gender: 1,
      resumeFilePath: '',
      candidatePhotoPath: '',
      email: '',
      mobile: '',
      secondaryMobile: '',
      yearOfExperience: 0,
      monthsOfExperience: 0,
      interviewType: InterviewType['Technical Interview - 1'],
      interviewerID: '',
      feedbackParameterIds: [],
      interviewCategory: Interview_Category.Technical,
      interviewRoundMode: InterviewMode['Tatvasoft Office'],
      interviewerLimit: NUMBER_OF_INTERVIEWERS[0],
      interviewStartDateTime: '',
      interviewEndDateTime: '',
      duration: 60,
      description: '',
      interviewTechnology: [],
      jobDesignation: '',
      isEdit: true,
      isScheduleInterviewEnabled: false,
      currentCompany: '',
      currentCTC: '',
      candidateLocation: '',
      location: '',
      candidateSource: CANDIDATE_SOURCE_TYPE[0]?.code || 1,
      referenceEmployeeName: ''
    });
    setFileName('');
    setImageName('');
    formik.resetForm();
  };

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      onHide(false);
      formik.resetForm();
      document.body.classList.remove('sidebar-open');
      dispatch(updateData());
      dispatch(updateCandidateData());
      dispatch(apiStatusClear());
      clearData();
      setFileName('');
      setImageName('');
      setStartDate(null);
      put(setIsUpdatedSuccess(false));
    }
  }, [isSucceed, isUpdatedSuccess]);

  useEffect(() => () => put(setIsUpdatedSuccess(false)), []);

  const formik = useFormik({
    initialValues: candidateData,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .min(2, 'Must be at least 2 characters')
        .max(15, 'Must be 15 characters or less')
        .matches(/^[a-z|A-Z|\s]+$/, 'First Name should only contain characters')
        .required('First Name is required.'),
      lastName: Yup.string()
        .trim()
        .min(2, 'Must be at least 2 characters')
        .max(20, 'Must be 20 characters or less')
        .matches(/^[a-z|A-Z|\s]+$/, 'Last Name should only contain characters')
        .required('Last Name is required.'),
      mobile: Yup.string()
        .trim()
        .matches(/^[0-9]{10}$/, 'Mobile Number Must Be 10 Digits Only.')
        .required('Mobile Number is required.'),
      secondaryMobile: Yup.string()
        .trim()
        .matches(/^[0-9]{10}$/, 'Mobile Number Must Be 10 Digits Only.')
        .when('mobile', {
          is: (mobile) => mobile && mobile.length === 10,
          then: Yup.string().notOneOf(
            [Yup.ref('mobile')],
            'Secondary Mobile Number is same as the Mobile Number.'
          )
        }),
      gender: Yup.string(['1', '2']).required('Gender is required.'),
      isEdit: Yup.boolean(),
      isScheduleInterviewEnabled: Yup.boolean(),
      yearOfExperience: Yup.number()
        .min(0, 'Minimum 0 Year.')
        .max(100, 'Maximum 100 Years.')
        .typeError('Only numbers are allowed.')
        .required('Exp. in Years is Required.'),
      monthsOfExperience: Yup.number()
        .min(0, 'Minimum 0 Month.')
        .max(11, 'Maximum 11 Months.')
        .required('Exp. in Months is required.')
        .typeError('Only numbers are allowed.'),
      interviewTechnology: Yup.array()
        .required('Technology is required.')
        .min(1, 'Minimum 1 technology is required.'),

      email: Yup.string().when('isEdit', {
        is: true,
        then: Yup.string()
          .email('Enter a valid email address')
          .required('Email Address is required.')
      }),
      interviewType: Yup.string().when(['isEdit', 'isScheduleInterviewEnabled'], {
        is: true,
        then: Yup.string().required('Interview Type is required.')
      }),
      interviewRoundMode: Yup.string().when(['isEdit', 'isScheduleInterviewEnabled'], {
        is: true,
        then: Yup.string().typeError('Please select interview round mode')
      }),
      duration: Yup.number().when(['isEdit', 'isScheduleInterviewEnabled'], {
        is: true,
        then: Yup.number().typeError('Interview duration is required.')
      }),
      interviewerLimit: Yup.number().when(['isEdit', 'isScheduleInterviewEnabled'], {
        is: (isEdit, isScheduleInterviewEnabled) => isEdit && isScheduleInterviewEnabled,
        then: Yup.number()
          .min(1, 'Minimum Limit 1')
          .max(10, 'Maximum  Limit 10')
          .typeError('Only numbers are allowed!')
          .required('Interviewer Limit is required.')
      }),
      interviewerID: Yup.array().when(['isEdit', 'isScheduleInterviewEnabled'], {
        is: (isEdit, isScheduleInterviewEnabled) => isEdit && isScheduleInterviewEnabled,
        then: Yup.array().required('Interviewer is required.').min(1, 'Interviewer is required.')
      }),
      feedbackParameterIds: Yup.array().when(
        ['isEdit', 'isScheduleInterviewEnabled', 'interviewType'],
        {
          is: (isEdit, isScheduleInterviewEnabled, interviewType) =>
            isEdit &&
            isScheduleInterviewEnabled &&
            (interviewType === InterviewType['Technical Interview - 1'].toString() ||
              interviewType === InterviewType['Technical Interview - 2'].toString() ||
              interviewType === InterviewType['Management Round'].toString()),
          then: Yup.array()
            .required('Feedback Parameter is required.')
            .min(1, 'Feedback Parameter is required.')
        }
      ),
      interviewCategory: Yup.string(),
      jobDesignation: Yup.string().when(['isEdit', 'isScheduleInterviewEnabled'], {
        is: true,
        then: Yup.string().nullable()
      }),
      description: Yup.string()
        .trim()
        .max(TEXTAREA_LIMIT.MAX, 'Description should not exceed ${max} characters.')
        .nullable(),
      resumeFilePath: Yup.mixed().test(
        'format',
        'Only the following formats are accepted: .doc, .docx, .pdf',
        (value) => {
          if (!value) {
            return true;
          }
          return resumeValidation(value);
        }
      ),
      candidatePhotoPath: Yup.mixed()
        .test('format', 'Only the following formats are accepted: .png, .jpg, .jpeg', (value) => {
          if (!value) {
            return true;
          }
          return imageValidation(value);
        })
        .test('fileSize', 'The image size must be less than 1 MB', (value) => {
          if (!value) {
            return true;
          }
          return value.size <= 1024 * 1024; // Check if file size is less than 1 MB.
        }),
      candidateLocation: Yup.string('Candidate location must be of type string.')
        .trim()
        .required('Candidate location is required.'),
      location: Yup.string('Candidate Location must be of type string.')
        .trim()
        .when('candidateLocation', {
          is: 'other',
          then: Yup.string().required('Candidate Location is required.'),
          otherwise: Yup.string()
        }),
      currentCTC: Yup.number('Current CTC must be of type number.')
        .min(0, 'CTC should not be less then 0.')
        .max(99, 'CTC should not be more then 99.')
        .typeError('Only positive numbers are allowed.')
        .required('Current CTC is required.'),
      currentCompany: Yup.string('Current company must be of type string.')
        .trim()
        .required('Current Company is required.'),
      candidateSource: Yup.number()
        .required('Candidate source is required.')
        .oneOf(
          CANDIDATE_SOURCE_TYPE.map((candidateSource) => candidateSource.code),
          'Valid candidate source is required.'
        ),
      referenceEmployeeName: Yup.string('Candidate reference must be of type string.')
    }),

    onSubmit: (values) => {
      try {
        let selectedFeedbackParameterIds = filterFeedbackParams(
          values.feedbackParameterIds,
          feedbackParamsOptions
        );

        let addFormData = {};
        if (!ID && values.isScheduleInterviewEnabled) {
          addFormData = {
            interviewStartDateTime: startDate,
            interviewEndDateTime: addMinutes(startDate, values.duration),
            interviewDuration: values.duration,
            interviewType: values.interviewType,
            ...(values.interviewerID &&
              values.interviewerID?.length && { interviewerID: values.interviewerID }),
            ...(selectedFeedbackParameterIds && {
              feedbackParameters: selectedFeedbackParameterIds
            }),
            interviewerLimit: values.interviewerLimit,
            interviewCategory: values.interviewCategory,
            interviewRoundMode: values.interviewRoundMode,
            ...(values.jobDesignation && { jobDesignation: values.jobDesignation }),
            ...(values.description && { description: values.description }),
            isInterview: values.isScheduleInterviewEnabled
          };
        }
        const formData = {
          firstName: values.firstName?.trim(),
          lastName: values.lastName?.trim(),
          gender: values.gender,
          yearOfExperience: values.yearOfExperience,
          monthsOfExperience: values.monthsOfExperience,
          currentCompany: values.currentCompany?.trim(),
          candidateTechnology: values.interviewTechnology,
          currentCTC: values.currentCTC?.trim(),
          candidateLocation:
            values.candidateLocation === 'other'
              ? values.location?.trim()
              : values.candidateLocation?.trim(),
          candidateSource: values.candidateSource,
          referenceEmployeeName: values.referenceEmployeeName,
          ...addFormData
        };

        let postData = new FormData();
        Object.keys(formData).forEach((el) => {
          if (el === 'feedbackParameters') {
            postData.append('feedbackParameters', JSON.stringify(formData[el]));
          } else if (typeof el === 'string') {
            postData.append(el, formData[el]);
          } else {
            postData.append(el, JSON.stringify(formData[el]));
          }
        });

        if (ID) {
          // Only encrypt the mobile number if its provided for the edit candidate process.
          if (values.mobile) {
            postData.append('mobile', values.mobile.trim());
          }
          if (values.secondaryMobile) {
            postData.append('secondaryMobile', values.secondaryMobile.trim());
          }
          postData.append('email', values.email);
          fileName && postData.append('resumeFilePath', values.resumeFilePath);
          imageName && postData.append('candidatePhotoPath', values.candidatePhotoPath);
          dispatch(
            editCandidate({
              postData,
              CandidateId: ID
            })
          );
        } else {
          if (values.mobile) {
            postData.append('mobile', values.mobile.trim());
          }
          if (values.secondaryMobile) {
            postData.append('secondaryMobile', values.secondaryMobile.trim());
          }
          postData.append('email', values.email);
          fileName && postData.append('resumeFilePath', values.resumeFilePath);
          imageName && postData.append('candidatePhotoPath', values.candidatePhotoPath);
          dispatch(addCandidate(postData));
        }
      } catch (error) {
        console.log(error);
      }
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name, additionalError = '') => {
    let response = null;
    if (isFormFieldValid(name) || additionalError) {
      response = <small className="p-error">{formik.errors[name] || additionalError}</small>;
    }

    if (name === 'email' && candidates?.existCandidateId) {
      response = (
        <small className="p-error">
          Candidate already added. Click{' '}
          <span
            className="table-view-popup-link"
            onClick={() => handleView(candidates.existCandidateId)}>
            here
          </span>
          for details.
        </small>
      );
    }
    return response;
  };

  useEffect(() => {
    ID && dispatch(getCandidateDetails(ID));
  }, [dispatch, ID]);

  useEffect(() => {
    if (startDate && !ID) {
      const endDate = addMinutes(startDate, formik.values.duration);
      const interviewStartTime = moment(startDate).format();
      const interviewEndTime = moment(endDate).format();
      // const technologies =
      //   technologiesID &&
      //   interviewTechnologyType
      //     .filter((tech) => technologiesID.includes(tech.ID))
      //     .map((tech) => tech.Technology)
      //     .toString();
      dispatch(
        allInterviewers(
          interviewStartTime,
          interviewEndTime,
          formik.values.interviewTechnology?.length ? formik.values.interviewTechnology : '',
          '',
          formik.values.interviewType === InterviewType['HR Round'],
          formik.values.interviewType === InterviewType.Practical
        )
      );
    }
  }, [
    dispatch,
    startDate,
    formik.values.interviewType,
    formik.values.duration,
    formik.values.interviewTechnology
  ]);

  // React useEffect hooks.
  useEffect(() => {
    if (startDate) {
      formik.setFieldValue('interviewerID', []);
    }
  }, [formik.values.interviewType, formik.values.interviewTechnology, startDate]);

  const interviewerTemplate = (option) => {
    return (
      <>
        <ReactTooltip />
        <div data-tip={`${formatAvailabilityTime(option.InterviewerToAvailability)}`}>
          {!option.isAvailable ? (
            <span className="unavailable-interviewer-text-color mr-1">{option.name}</span>
          ) : (
            <span className="mr-1">{option.name}</span>
          )}
          {!!option?.UMap?.length && (
            <span className={`${!option.isAvailable && 'unavailable-interviewer-text-color'} mr-1`}>
              [{option?.UMap?.map((tech) => wordCapitalize(tech?.MapT?.Technology)).join(', ')}]
            </span>
          )}
          {!option.isAvailable && (
            <>
              {formatAvailabilityTime(option.InterviewerToAvailability) && (
                <span className="unavailable-interviewer-text-color mr-1">{`[${formatAvailabilityTime(
                  option.InterviewerToAvailability
                )}]`}</span>
              )}
              {/* <i
                className="pi pi-exclamation-triangle unavailable-interviewer-text-color"
                data-tip="Might not be available for the above schedule selected"
              /> */}
            </>
          )}
          {!!option?.IsPracticalAdmin && (
            <Badge value="Practical Admin" className="practical-admin" severity="success"></Badge>
          )}
        </div>
      </>
    );
  };

  const feedbackParamsOptionsGenerate = (options) => {
    if (!options?.length) return [];

    const committedSingleParams = {
      single: new Set(),
      group: new Set()
    };
    const selectedParameterIds = new Set(formik.values.feedbackParameterIds);

    // Collect committed single parameters
    options.forEach((opt) => {
      if (selectedParameterIds.has(opt.ID)) {
        if (opt.GroupParameters?.length) {
          opt.GroupParameters.forEach((child) =>
            committedSingleParams.group.add(child.Parameter.ID)
          );
        } else {
          committedSingleParams.single.add(opt.ID);
        }
      }
    });

    // Generate parameter options
    const paramsOptions = options.map((opt) => ({
      name: opt.ParameterName,
      code: opt.ID,
      key: opt.ID,
      childParams:
        opt.GroupParameters?.map((child) => ({
          id: child.Parameter.ID || '',
          parameterName: child.Parameter.ParameterName || ''
        })) || [],
      disabled: isOptionDisabled(opt, selectedParameterIds, committedSingleParams)
    }));

    return paramsOptions;
  };

  // Helper function to determine if an option is disabled
  const isOptionDisabled = (opt, selectedParameterIds, committedSingleParams) => {
    if (selectedParameterIds.has(opt.ID)) {
      if (opt.GroupParameters?.length) {
        opt.GroupParameters.forEach((child) => {
          if (selectedParameterIds.has(child.Parameter.ID)) {
            // Remove the child ID from selectedParameterIds
            selectedParameterIds.delete(child.Parameter.ID);
            formik.setFieldValue('feedbackParameterIds', [...selectedParameterIds]);
          }
        });
      }
      return false;
    }

    if (opt.GroupParameters?.length) {
      return opt.GroupParameters.some((child) =>
        committedSingleParams.group.has(child.Parameter.ID)
      );
    }

    return committedSingleParams.single.has(opt.ID) || committedSingleParams.group.has(opt.ID);
  };

  useEffect(() => {
    const modifiedOptions = feedbackParamsOptionsGenerate(filterParameters || []);
    setFeedbackParamsOptions(modifiedOptions);
  }, [filterParameters, formik.values.feedbackParameterIds]);

  useEffect(() => {
    if (show && formik.values.isScheduleInterviewEnabled) {
      dispatch(getFilterCommonParamsList(queryParamsOfFeedbackParams));
    }
  }, [show, formik.values.isScheduleInterviewEnabled]);

  const feedBackParamsTemplate = (option) => {
    return (
      <>
        <span className="mr-1">{wordCapitalize(option.name)}</span>
        {!!option?.childParams?.length && (
          <span>
            [{option?.childParams?.map((tech) => wordCapitalize(tech?.parameterName)).join(', ')}]
          </span>
        )}
      </>
    );
  };

  return (
    <>
      <AvailabilityDetails onHide={onTogglePopup} show={showPopup} />
      <Sidebar
        ID={ID}
        visible={show}
        onHide={() => {
          onHide(true);
          document.body.classList.remove('sidebar-open');
        }}
        position="right"
        className="sidebar-drawer">
        <div className="form-box-wrapper">
          <div className="title-wrapper">
            <p className="card-title">{ID ? 'Edit' : 'Add'} Candidate</p>
            <button className="p-sidebar-close" onClick={() => clearData()}>
              <span className="p-sidebar-close-icon pi pi-times" />
            </button>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className=""
            autoComplete="off"
            encType="multipart/form-data">
            <div className="form-row-wrapper">
              <div className="form-col full-width">
                <div className="form-row-wrapper">
                  <div className="form-col">
                    <div className="custom-form-group">
                      <Label htmlFor="firstName" text={LABEL_TYPES.FIRST_NAME} isMandatory />
                      <InputText
                        autoFocus
                        id="firstName"
                        name="firstName"
                        value={formik.values.firstName}
                        placeholder={LABEL_TYPES.FIRST_NAME + ' *'}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        className={classNames({
                          'p-invalid': isFormFieldValid('firstName'),
                          'w-full': true
                        })}
                      />
                      {getFormErrorMessage('firstName')}
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="custom-form-group">
                      <Label htmlFor="lastName" text={LABEL_TYPES.LAST_NAME} isMandatory />
                      <InputText
                        id="lastName"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        placeholder={LABEL_TYPES.LAST_NAME + ' *'}
                        onBlur={formik.handleBlur}
                        className={classNames({
                          'p-invalid': isFormFieldValid('lastName'),
                          'w-full': true
                        })}
                      />
                      {getFormErrorMessage('lastName')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="email" text={LABEL_TYPES.EMAIL} isMandatory />
                    <InputText
                      id="email"
                      name="email"
                      disabled={ID ? true : false}
                      value={formik.values.email}
                      placeholder={LABEL_TYPES.EMAIL + ' *'}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        if (e.target.value && !formik.errors.email) {
                          dispatch(
                            checkEmailMobile({
                              email: e.target.value?.trim(),
                              ...(ID && { userId: ID })
                            })
                          );
                        }
                      }}
                      className={classNames({
                        'p-invalid': isFormFieldValid('email'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('email')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-row-wrapper">
                  <div className="form-col">
                    <div className="custom-form-group">
                      <Label htmlFor="mobile" text={LABEL_TYPES.MOBILE} isMandatory />
                      <InputText
                        id="mobile"
                        name="mobile"
                        keyfilter="int"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          if (e.target.value && !formik.errors.mobile) {
                            dispatch(
                              checkEmailMobile({
                                mobile: e.target.value?.trim(),
                                ...(ID && { userId: ID })
                              })
                            );
                          }
                        }}
                        placeholder={LABEL_TYPES.MOBILE + ' *'}
                        className={classNames({
                          'p-invalid': isFormFieldValid('mobile'),
                          'w-full': true
                        })}
                      />
                      {getFormErrorMessage('mobile')}
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="custom-form-group">
                      <Label htmlFor="secondaryMobile" text={LABEL_TYPES.SECONDARY_MOBILE} />
                      <InputText
                        id="secondaryMobile"
                        name="secondaryMobile"
                        keyfilter="int"
                        value={formik.values.secondaryMobile}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          if (e.target.value && !formik.errors.secondaryMobile) {
                            dispatch(
                              checkEmailMobile({
                                secondaryMobile: e.target.value?.trim(),
                                ...(ID && { userId: ID })
                              })
                            );
                          }
                        }}
                        placeholder={LABEL_TYPES.SECONDARY_MOBILE}
                        className={classNames({
                          'p-invalid': isFormFieldValid('secondaryMobile'),
                          'w-full': true
                        })}
                      />
                      {getFormErrorMessage('secondaryMobile')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="currentCompany"
                      text={LABEL_TYPES.CURRENT_COMPANY}
                      isMandatory
                    />
                    <InputText
                      id="currentCompany"
                      name="currentCompany"
                      value={formik.values.currentCompany}
                      placeholder={`${LABEL_TYPES.CURRENT_COMPANY} *`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('currentCompany'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('currentCompany')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="interviewTechnology"
                      text={LABEL_TYPES.TECHNOLOGY}
                      isMandatory
                    />
                    <MultiSelect
                      filter
                      name="interviewTechnology"
                      id="interviewTechnology"
                      optionLabel="Technology"
                      optionValue="ID"
                      value={formik.values.interviewTechnology}
                      forceSelection
                      options={interviewTechnologyType}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      display="chip"
                      onBlur={formik.handleBlur}
                      placeholder="Select Technology *"
                      className={classNames({
                        'p-invalid': isFormFieldValid('interviewTechnology'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('interviewTechnology')}
                </div>
              </div>

              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="currentCTC" text={LABEL_TYPES.CURRENT_CTC} isMandatory />
                    <div className="p-inputgroup w-full">
                      <InputText
                        id="currentCTC"
                        name="currentCTC"
                        value={formik.values.currentCTC}
                        placeholder={LABEL_TYPES.CURRENT_CTC}
                        onChange={formik.handleChange}
                        className={classNames({
                          'p-invalid': isFormFieldValid('currentCTC'),
                          'w-full': true
                        })}
                        onBlur={formik.handleBlur}
                        keyfilter={'money'}
                      />
                      <span
                        style={{ backgroundColor: 'inherit' }}
                        className="p-inputgroup-addon add-candidate-lpa">
                        LPA
                      </span>
                    </div>
                  </div>
                  {getFormErrorMessage('currentCTC')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="candidateLocation"
                      text={LABEL_TYPES.CANDIDATE_CURRENT_LOCATION}
                      isMandatory
                    />
                    <Dropdown
                      id="candidateLocation"
                      name="candidateLocation"
                      value={formik.values.candidateLocation}
                      optionLabel="name"
                      optionValue="code"
                      options={CANDIDATE_LOCATIONS}
                      onChange={formik.handleChange}
                      panelClassName="custom-dropdown-panel"
                      className={classNames({
                        'p-invalid': isFormFieldValid('candidateLocation'),
                        'w-full': true
                      })}
                    />
                    {formik.values.candidateLocation === 'other' && (
                      <InputText
                        id="location"
                        name="location"
                        value={formik.values.location}
                        placeholder={LABEL_TYPES.CANDIDATE_CURRENT_LOCATION}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={classNames('candidate-location-other', {
                          'p-invalid': isFormFieldValid('location'),
                          'w-full': true
                        })}
                      />
                    )}
                  </div>
                  {getFormErrorMessage('candidateLocation') || getFormErrorMessage('location')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="gender" text={LABEL_TYPES.GENDER} isBold isMandatory />
                    <div className="radio-list-wrapper">
                      <div className="form-group-outer-radiobutton">
                        <RadioButton
                          inputId="male"
                          value={1}
                          name="gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.gender === 1}
                        />
                        <Label htmlFor="male" text={LABEL_TYPES.MALE} isBold />
                      </div>
                      <div className="form-group-outer-radiobutton">
                        <RadioButton
                          inputId="female"
                          value={2}
                          name="gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.gender === 2}
                        />
                        <Label htmlFor="female" text={LABEL_TYPES.FEMALE} isBold />
                      </div>
                    </div>
                  </div>
                  {getFormErrorMessage('gender')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-row-wrapper">
                  <div className="form-col ">
                    <div className="custom-form-group">
                      <Label
                        htmlFor="yearOfExperience"
                        text={LABEL_TYPES.YEAR_EXPERIENCE}
                        isMandatory
                      />
                      <InputText
                        id="yearOfExperience"
                        name="yearOfExperience"
                        placeholder="Years"
                        type="number"
                        value={formik.values.yearOfExperience}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={classNames({
                          'p-invalid': isFormFieldValid('yearOfExperience')
                        })}
                      />
                      {getFormErrorMessage('yearOfExperience')}
                    </div>
                  </div>
                  <div className="form-col ">
                    <div className="custom-form-group">
                      <Label
                        htmlFor="monthsOfExperience"
                        text={LABEL_TYPES.MONTH_EXPERIENCE}
                        isMandatory
                      />
                      <InputText
                        id="monthsOfExperience"
                        name="monthsOfExperience"
                        placeholder="0-11"
                        type="number"
                        value={formik.values.monthsOfExperience}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={classNames({
                          'p-invalid': isFormFieldValid('monthsOfExperience')
                        })}
                      />
                      {getFormErrorMessage('monthsOfExperience')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="candidateSource"
                      text={LABEL_TYPES.CANDIDATE_SOURCE}
                      isBold
                      isMandatory
                    />
                    <Dropdown
                      id="candidateSource"
                      name="candidateSource"
                      value={formik.values.candidateSource}
                      optionLabel="name"
                      optionValue="code"
                      options={CANDIDATE_SOURCE_TYPE}
                      onChange={formik.handleChange}
                      panelClassName="custom-dropdown-panel"
                      className={classNames({
                        'p-invalid': isFormFieldValid('candidateSource'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage('candidateSource')}
                </div>
              </div>
              {formik.values.candidateSource ===
                CANDIDATE_SOURCE_TYPE[CANDIDATE_SOURCE_TYPE.length - 1].code && (
                <>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="referenceEmployeeName"
                          text={LABEL_TYPES.CANDIDATE_SOURCE_REFERENCE}
                          isBold
                        />
                        <InputText
                          id="referenceEmployeeName"
                          name="referenceEmployeeName"
                          value={formik.values.referenceEmployeeName}
                          placeholder={LABEL_TYPES.CANDIDATE_SOURCE_REFERENCE}
                          onChange={formik.handleChange}
                        />
                      </div>
                      {getFormErrorMessage('candidateSource')}
                    </div>
                  </div>
                </>
              )}
              {!ID && (
                <div className="form-col full-width">
                  <div className="form-group-outer">
                    <div className="custom-form-group">
                      <div className="wrapper-text">
                        <Label
                          htmlFor="isScheduleInterviewEnabled"
                          text={LABEL_TYPES.SCHEDULE_INTERVIEW}
                          isBold
                        />
                        <InputSwitch
                          name="isScheduleInterviewEnabled"
                          checked={formik.values.isScheduleInterviewEnabled}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {formik.values.isScheduleInterviewEnabled && (
                <>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <CustomDatePicker
                          value={startDate}
                          onChange={onDateChange}
                          minDateTime={new Date().setDate(new Date().getDate())}
                          minTime={new Date().setHours(0, 0, 0)}
                          maxTime={new Date().setHours(23, 59, 59)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewType"
                          text={LABEL_TYPES.INTERVIEW_TYPE}
                          isMandatory
                          isBold
                        />
                        <Dropdown
                          name="interviewType"
                          id="interviewType"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.interviewType}
                          options={INTERVIEW_TYPE}
                          onChange={(e) => {
                            formik.handleChange(e);
                            if (isFirstTime && formik.values.interviewTechnology?.length) {
                              formik.setFieldValue(
                                'feedbackParameterIds',
                                formik.values.interviewTechnology || []
                              );
                            } else {
                              formik.setFieldValue('feedbackParameterIds', []);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          placeholder="Select Interview Type *"
                          panelClassName="custom-dropdown-panel"
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewType'),
                            'w-full': true
                          })}
                        />
                      </div>
                    </div>
                    {getFormErrorMessage('interviewType')}
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewRoundMode"
                          text={LABEL_TYPES.INTERVIEW_ROUND_MODE}
                          isMandatory
                        />
                        <Dropdown
                          name="interviewRoundMode"
                          id="interviewRoundMode"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.interviewRoundMode}
                          options={INTERVIEW_MODE}
                          onChange={formik.handleChange}
                          placeholder="Select Interview Mode"
                          panelClassName="custom-dropdown-panel"
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewRoundMode')
                          })}
                        />
                      </div>
                      {getFormErrorMessage('interviewRoundMode')}
                    </div>
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewCategory"
                          text={LABEL_TYPES.INTERVIEW_CATEGORY}
                          isBold
                          isMandatory
                        />
                        <Dropdown
                          id="interviewCategory"
                          name="interviewCategory"
                          value={formik.values.interviewCategory}
                          optionLabel="name"
                          optionValue="code"
                          options={INTERVIEW_CATEGORY}
                          onChange={formik.handleChange}
                          panelClassName="custom-dropdown-panel"
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewCategory'),
                            'w-full': true
                          })}
                        />
                      </div>
                      {getFormErrorMessage('interviewCategory')}
                    </div>
                  </div>
                  {RENDER.No && (
                    <div className="form-col full-width">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label
                            htmlFor="jobDesignation"
                            text={LABEL_TYPES.DESIGNATION}
                            isMandatory
                          />
                          <AutoComplete
                            field="Designation"
                            name="jobDesignation"
                            id="jobDesignation"
                            forceSelection
                            value={designationText}
                            suggestions={designationOptions}
                            placeholder={LABEL_TYPES.DESIGNATION}
                            completeMethod={handleDesignationSearch}
                            onChange={(e) => {
                              setDesignationText(e.target.value);
                              formik.setFieldValue('jobDesignation', e.target.value?.ID);
                            }}
                            onBlur={formik.handleBlur}
                            className={classNames({
                              'p-invalid': isFormFieldValid('jobDesignation'),
                              'w-full': true
                            })}
                          />
                        </div>
                      </div>
                      {getFormErrorMessage('jobDesignation')}
                    </div>
                  )}

                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="duration"
                          text={LABEL_TYPES.INTERVIEW_DURATION}
                          isMandatory
                          isBold
                        />
                        <Dropdown
                          id="duration"
                          name="duration"
                          value={formik.values.duration}
                          options={INTERVIEW_DURATION}
                          optionLabel="name"
                          optionValue="code"
                          onChange={formik.handleChange}
                          panelClassName="custom-dropdown-panel"
                          placeholder="Select Interview Duration"
                        />
                      </div>
                      {getFormErrorMessage('duration')}
                    </div>
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewerLimit"
                          text={LABEL_TYPES.INTERVIEWER_LIMIT}
                          isMandatory
                          isBold
                        />
                        <Dropdown
                          id="interviewerLimit"
                          name="interviewerLimit"
                          value={formik.values.interviewerLimit}
                          options={NUMBER_OF_INTERVIEWERS}
                          onChange={formik.handleChange}
                          placeholder="Select Number of Interviewers"
                          className="w-full custom-dropdown"
                          panelClassName="custom-dropdown-panel"
                        />
                      </div>
                      {getFormErrorMessage('interviewerLimit')}
                    </div>
                  </div>

                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="interviewerID"
                          text={LABEL_TYPES.INTERVIEWERS}
                          isMandatory
                          isBold
                          isChildren
                          tooltip="Please select Date and Time (time must be between 8 AM to 8 PM)"
                        />
                        <AvailabilityButton onClick={onTogglePopup} />
                        <MultiSelect
                          filter
                          name="interviewerID"
                          id="interviewerID"
                          optionLabel="name"
                          optionValue="code"
                          forceSelection
                          value={formik.values.interviewerID}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          options={interviewerOptions}
                          placeholder="Select Interviewer *"
                          display="chip"
                          itemTemplate={interviewerTemplate}
                          filterBy="search"
                          className={classNames({
                            'p-invalid': isFormFieldValid('interviewerID'),
                            'w-full': true
                          })}
                        />
                      </div>
                      {getFormErrorMessage('interviewerID')}
                    </div>
                  </div>

                  {(formik.values.interviewType === InterviewType['Technical Interview - 1'] ||
                    formik.values.interviewType === InterviewType['Technical Interview - 2'] ||
                    formik.values.interviewType === InterviewType['Management Round']) && (
                    <div className="form-col full-width">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label
                            htmlFor="feedbackParameterIds"
                            text={LABEL_TYPES.FEEDBACK_PARAMETERS}
                            isMandatory
                            isBold
                          />
                          <MultiSelect
                            name="feedbackParameterIds"
                            id="feedbackParameterIds"
                            optionLabel="name"
                            optionValue="code"
                            value={formik.values.feedbackParameterIds}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setIsFirstTime(false);
                            }}
                            options={feedbackParamsOptions}
                            itemTemplate={feedBackParamsTemplate}
                            placeholder="Select Feedback Params"
                            filter
                            showSelectAll={false}
                            display="chip"
                            className={classNames({
                              'p-invalid': isFormFieldValid('feedbackParameterIds')
                            })}
                          />
                        </div>
                        {getFormErrorMessage('feedbackParameterIds')}
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="form-col full-width">
                <div className="form-group-outer upload-document-wrapper">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="file"
                      text={
                        !ID
                          ? BUTTON_TYPES.UPLOAD_CANDIDATE_IMAGE
                          : BUTTON_TYPES.UPDATE_CANDIDATE_IMAGE
                      }
                      isBold
                    />
                    <input
                      hidden
                      ref={imageRef}
                      type="file"
                      id="candidatePhotoPath"
                      name="candidatePhotoPath"
                      onChange={(e) => {
                        formik.setFieldValue('candidatePhotoPath', e.target.files[0]);
                        setImageName(e.target.files[0].name);
                        setIsImage('');
                      }}
                      accept="image/png, image/jpeg ,image/jpg"
                      className={classNames({
                        'p-invalid': isFormFieldValid('candidatePhotoPath')
                      })}
                    />
                  </div>
                  <div className="flex flex-wrap align-items-center">
                    <CustomButton
                      variant="contained"
                      className="gray-btn"
                      onClick={() => {
                        imageRef.current.click();
                      }}>
                      <div>
                        <i className="pi pi-fw pi-upload mr-1" />
                        <span>
                          {!ID
                            ? BUTTON_TYPES.UPLOAD_CANDIDATE_IMAGE
                            : BUTTON_TYPES.UPDATE_CANDIDATE_IMAGE}
                        </span>
                      </div>
                    </CustomButton>
                    {ID ? (
                      isImage ? (
                        <ResumeButton
                          onClick={() => openResumeLink(`${profilePathBase}/${ID}/${isImage}`)}
                        />
                      ) : (
                        <p>{imageName}</p>
                      )
                    ) : (
                      <p>{imageName}</p>
                    )}
                  </div>
                </div>
                {getFormErrorMessage('candidatePhotoPath')}
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer upload-document-wrapper">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="file"
                      text={!ID ? LABEL_TYPES.UPLOAD_RESUME : LABEL_TYPES.UPDATE_RESUME}
                      isBold
                    />
                    <input
                      hidden
                      ref={fileRef}
                      type="file"
                      id="resumeFilePath"
                      name="resumeFilePath"
                      onChange={(e) => {
                        formik.setFieldValue('resumeFilePath', e.target.files[0]);
                        setFileName(e.target.files[0].name);
                        setIsFile('');
                      }}
                      accept=".doc, .docx, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      className={classNames({
                        'p-invalid': isFormFieldValid('resumeFilePath')
                      })}
                    />
                  </div>
                  <div className="flex flex-wrap align-items-center">
                    <CustomButton
                      variant="contained"
                      className="gray-btn"
                      onClick={() => {
                        fileRef.current.click();
                      }}>
                      <div>
                        <i className="pi pi-fw pi-upload mr-1" />
                        <span>{!ID ? BUTTON_TYPES.UPLOAD_RESUME : BUTTON_TYPES.UPDATE_RESUME}</span>
                      </div>
                    </CustomButton>
                    {ID ? (
                      isFile ? (
                        <ResumeButton
                          onClick={() => openResumeLink(`${pathBase}/${ID}/${isFile}`)}
                        />
                      ) : (
                        <p>{fileName}</p>
                      )
                    ) : (
                      <p>{fileName}</p>
                    )}
                  </div>
                </div>
                {getFormErrorMessage('resumeFilePath')}
              </div>
            </div>

            <div className="form-btn-wrapper">
              <CustomButton type="submit" variant="contained" disabled={isLoading}>
                {BUTTON_TYPES.SAVE}
              </CustomButton>
              <CustomButton
                variant="contained"
                onClick={() => clearData()}
                color="error"
                className="gray-btn">
                {BUTTON_TYPES.CANCEL}
              </CustomButton>
            </div>
          </form>
        </div>
      </Sidebar>
    </>
  );
};

export default AddEditCandidates;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EqualHeight } from 'react-equal-height';

// Constants
import { ROLES, ROUTES, STATUS_INTERVIEW, USER_TYPE_TEXT } from '../../constants/common';

// Images
import interviewIc from '@assets/images/inteview.svg';
import interviewIcDark from '@assets/images/interview-dark.svg';
// import candidateIc from '@assets/images/candidates-ic.svg';
// import user from '@assets/images/candidates-ic.svg';
import arrowRight from '@assets/images/arrow-right.svg';

// Utility Functions
import { getDateWithDay } from '../../utils/date';
import { wordCapitalize } from '../../utils/common';
import { getScheduleInterviewCountByHR } from '../../redux/actions/hr';

// Prime-React Components
import DoughnutChart from '../../components/DoughnutChart';
import PieChart from '../../components/PieChart';
// import UpcommingDoughnutChart from '../../components/UpcommingDoughnutChart';
import AvailableInterviewersBarChart from '../../components/AvailableInterviewersBarChart';

// Redux-Actions
import { getAdminDashboardCount, getInterviewerRound } from '../../redux/actions/admin';

// Utility Packages
import moment from 'moment';
import { Link } from 'react-router-dom';

const Schedule = () => {
  const dispatch = useDispatch();
  const { dashboardCountData, interviewRoundData } = useSelector((state) => state.adminDashboard);
  const { userType } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getAdminDashboardCount());
    dispatch(getInterviewerRound());
    dispatch(
      getScheduleInterviewCountByHR(
        getDateWithDay(moment().startOf('day').toDate()),
        getDateWithDay(moment().endOf('day').toDate())
      )
    );
  }, [dispatch]);

  //for cards
  // function parseData(data) {
  //   if (!data) {
  //     return { candidates: [], hrs: [], interviewers: [] };
  //   } else {
  //     const { candidates, hrs, interviewers } = data;
  //     return {
  //       candidates: [{ name: 'Total', value: candidates?.totalCandidates }],
  //       hrs: [
  //         { name: 'Total', value: hrs?.totalHRs },
  //         { name: 'Active', value: hrs?.activeHRs },
  //         { name: 'Inactive', value: hrs?.inactiveHRs }
  //       ],
  //       interviewers: [
  //         { name: 'Total', value: interviewers?.totalInterviewers },
  //         { name: 'Active', value: interviewers?.activeInterviewers },
  //         { name: 'Inactive', value: interviewers?.inactiveInterviewers }
  //       ]
  //     };
  //   }
  // }
  // const { interviewers } = parseData(dashboardCountData);

  return (
    <div className="hr-dashboard">
      <div className="white-box-grid dashboard-card-list hr-dashboard">
        <div className="white-box-item small-width">
          <div className="white-box-item dashboard-card-item full-width dashboard-card-item-height dashboard-card-upper-item">
            <div className="white-box align-center">
              <div className="card-content full-width">
                <div className="card-title-block align-items-center">
                  <div className="content">
                    <p className="number">
                      {(!!interviewRoundData?.length &&
                        interviewRoundData.filter(
                          (round) =>
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_PENDING ||
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE ||
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE ||
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_PENDING ||
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE ||
                            round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE ||
                            round.Status === STATUS_INTERVIEW.MANAGEMENT_PENDING ||
                            round.Status === STATUS_INTERVIEW.MANAGEMENT_SCHEDULE ||
                            round.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE ||
                            round.Status === STATUS_INTERVIEW.PRACTICAL_PENDING ||
                            round.Status === STATUS_INTERVIEW.PRACTICAL_SCHEDULE ||
                            round.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE ||
                            round.Status === STATUS_INTERVIEW.HR_PENDING ||
                            round.Status === STATUS_INTERVIEW.HR_SCHEDULE ||
                            round.Status === STATUS_INTERVIEW.HR_RESCHEDULE
                        )?.length) ||
                        0}
                    </p>
                    <p className="card-title">{USER_TYPE_TEXT.PLANED_INTERVIEWS}</p>
                  </div>
                  <div className="icon-block">
                    <i>
                      <img src={interviewIc} alt="interview" className="normal-img" />
                      <img src={interviewIcDark} alt="interview" className="dark-theme-img" />
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link
            to={
              userType === (ROLES.HR_AND_INTERVIEWER || ROLES.HR)
                ? ROUTES.HR.INTERVIEW_SCHEDULE
                : ''
            }>
            <div className="white-box-item dashboard-card-item full-width dashboard-card-item-height dashboard-card-lower-item interview-outer">
              <div className="white-box align-center">
                <div className="card-content full-width">
                  <div className="card-title-block align-items-center">
                    <div className="card-title-wrapper">
                      <span className="card-title">Highest interviews taken (Last 15 Days)</span>
                    </div>
                  </div>
                  <div className="interview-wrapper">
                    {dashboardCountData?.interviewers?.topInterviewers?.length &&
                      dashboardCountData?.interviewers?.topInterviewers.map((interviewer, key) => {
                        return (
                          <div className="interview-list" key={key}>
                            <div className="interview-inner" key={key}>
                              <div className="count">{interviewer?.InvitationsCount}</div>
                              <div className="interview-name">
                                <span>{`${wordCapitalize(interviewer?.FirstName)} ${wordCapitalize(
                                  interviewer?.LastName
                                )}`}</span>
                                <i className="icon">
                                  <img src={arrowRight} alt="arrow-right" />
                                </i>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <EqualHeight>
          <DoughnutChart />
        </EqualHeight>
        <EqualHeight>
          <PieChart />
          {/* <UpcommingDoughnutChart /> */}
          <AvailableInterviewersBarChart />
          {/* <TechnologyBarchart /> */}
        </EqualHeight>
      </div>
    </div>
  );
};

export default Schedule;

import React, { useEffect, useRef, useState } from 'react';
import { EqualHeightElement } from 'react-equal-height';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Prime-React Components
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-datalabels';

// Components
import { CustomeTooltip } from './ActionButtons';

// Redux-Actions
import { getTechnologyWithFilter } from '../redux/actions/admin';

// Constants
import {
  DashboardStatusGraphlabels,
  INTERVIEW_BACKGROUND_COLORS_DARK_MODE,
  INTERVIEW_COLORS,
  INTERVIEW_STATUS_LIST,
  STATUS_INTERVIEW
} from '../constants/common';

// Images
import filterIc from '@assets/images/filter-ic.svg';
import filterIcDark from '@assets/images/filter-ic-dark.svg';

// Utils
import { getDateWithDay } from '../utils/date';
import { getBarOptions } from '../utils/chart';
import { isDarkMode, wordCapitalize } from '../utils/common';

const PieChart = () => {
  const dispatch = useDispatch();
  const { interviewRoundData, pieChartFilterdData } = useSelector((state) => state.adminDashboard);
  const scheduleInterviewCount = useSelector((state) => state.hrs.scheduleInterviewCount);

  const durationFilterRef = useRef(null);
  const hrFilterRef = useRef(null);
  const [roundStatusCode, setRoundStatusCode] = useState({});
  const [selectedCount, setSelectedCount] = useState({});
  const [rejectedCount, setRejectedCount] = useState({});
  const [filterStatus, setStatusFilter] = useState('day');
  const [hrData, setHrData] = useState([]);
  const [selectedHR, setSelectedHR] = useState('');
  const [chartData, setChartData] = useState([]);

  function modifiedHrData(data) {
    const scheduleInterviews = data || [];
    let labels = [
      {
        label: 'All',
        value: '',
        command: () => setSelectedHR(''),
        className: selectedHR === '' ? 'highlighted-item' : ''
      }
    ];

    scheduleInterviews.forEach((hr) => {
      let hrName = `${wordCapitalize(hr?.FirstName || '')} ${wordCapitalize(hr.LastName || '')}`;
      labels.push({
        label: hrName,
        value: hr?.ID,
        command: () => setSelectedHR(hr?.ID),
        className: selectedHR === hr?.ID ? 'highlighted-item' : ''
      });
    });
    setHrData(labels);
  }

  useEffect(() => {
    modifiedHrData(scheduleInterviewCount);
  }, [scheduleInterviewCount]);

  useEffect(() => {
    let data = (interviewRoundData?.length !== 0 ? interviewRoundData : pieChartFilterdData) || [];
    const modifiedInterviewRoundStatus = data.map((item) => {
      return {
        HR: {
          ...item.HROfRound
        },
        Status: item.Status,
        Result: item.Result
      };
    });

    setChartData(modifiedInterviewRoundStatus);
  }, [interviewRoundData, pieChartFilterdData]);

  useEffect(() => {
    const modifiedInterviewRoundStatus = (pieChartFilterdData || []).map((item) => {
      return {
        HR: {
          ...item.HROfRound
        },
        Status: item.Status,
        Result: item.Result
      };
    });

    setChartData(modifiedInterviewRoundStatus);
  }, [pieChartFilterdData]);

  const interviewStatusObject = () => {
    return DashboardStatusGraphlabels.reduce((obj, item) => {
      obj[item] = 0;
      return obj;
    }, {});
  };

  const setDefaultValue = () => {
    return DashboardStatusGraphlabels.reduce((obj, item) => {
      obj[item] = null;
      return obj;
    }, {});
  };

  const getInterviewRoundName = (status) => {
    return INTERVIEW_STATUS_LIST.filter((item) => item.code === status)[0]?.name;
  };

  const generateLabelForChart = () => {
    return DashboardStatusGraphlabels.map((status) => {
      const parts = status.split(' ');
      const first = parts.shift(); // First word
      const last = parts.pop(); // Last word
      const middle = parts.join(' '); // Middle part
      return [first, middle, last].filter((part) => part !== '' && part !== undefined);
    });
  };

  // for interview status
  function filterData(data) {
    if (!data?.length) {
      return { statusData: [] };
    }

    const statusCodeData = interviewStatusObject();
    const rejectedData = setDefaultValue();
    const selectedData = setDefaultValue();

    const completeStatuses = [
      STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE,
      STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE,
      STATUS_INTERVIEW.PRACTICAL_COMPLETE,
      STATUS_INTERVIEW.HR_COMPLETE,
      STATUS_INTERVIEW.MANAGEMENT_COMPLETE
    ];

    // Helper to process a single round
    const processRound = (round) => {
      const roundName = getInterviewRoundName(round.Status);
      if (completeStatuses.includes(round.Status)) {
        if (round.Result === 1) {
          selectedData[roundName] = (selectedData[roundName] || 0) + 1;
        } else if (round.Result === 2) {
          rejectedData[roundName] = (rejectedData[roundName] || 0) + 1;
        }
      } else {
        statusCodeData[roundName] = (statusCodeData[roundName] || 0) + 1;
      }
    };

    data.forEach((round) => {
      // Only process rounds matching the selectedHR if one is specified
      if (!selectedHR || (selectedHR && selectedHR === round?.HR?.ID)) {
        processRound(round);
      }
    });

    setRoundStatusCode(statusCodeData);
    setSelectedCount(selectedData);
    setRejectedCount(rejectedData);
  }

  useEffect(() => {
    filterData(chartData);
    modifiedHrData(scheduleInterviewCount);
  }, [interviewRoundData, chartData, selectedHR]);

  useEffect(() => {
    filterData(chartData);
    modifiedHrData(scheduleInterviewCount);
  }, [pieChartFilterdData, chartData, selectedHR]);

  useEffect(() => {
    var startOfWeek = getDateWithDay(moment().startOf(filterStatus).toDate());
    var endOfWeek = getDateWithDay(moment().endOf(filterStatus).toDate());

    if (filterStatus) {
      dispatch(getTechnologyWithFilter(startOfWeek, endOfWeek));
    }
  }, [dispatch, filterStatus]);

  const statusFilter = [
    {
      label: 'Today',
      value: 1,
      command: () => setStatusFilter('day'),
      className: filterStatus === 'day' ? 'highlighted-item' : ''
    },
    {
      label: 'Weekly',
      value: 1,
      command: () => setStatusFilter('week'),
      className: filterStatus === 'week' ? 'highlighted-item' : ''
    },
    {
      label: 'Monthly',
      value: 2,
      command: () => setStatusFilter('month'),
      className: filterStatus === 'month' ? 'highlighted-item' : ''
    }
  ];

  const darkModeColors = [
    // INTERVIEW_BACKGROUND_COLORS_DARK_MODE.PENDING,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.SCHEDULE,
    // INTERVIEW_BACKGROUND_COLORS_DARK_MODE.RESCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.COMPLETE,
    // INTERVIEW_BACKGROUND_COLORS_DARK_MODE.PENDING,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.SCHEDULE,
    // INTERVIEW_BACKGROUND_COLORS_DARK_MODE.RESCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.COMPLETE,
    // INTERVIEW_BACKGROUND_COLORS_DARK_MODE.PENDING,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.SCHEDULE,
    // INTERVIEW_BACKGROUND_COLORS_DARK_MODE.RESCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.COMPLETE,
    // INTERVIEW_BACKGROUND_COLORS_DARK_MODE.PENDING,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.SCHEDULE,
    // INTERVIEW_BACKGROUND_COLORS_DARK_MODE.RESCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.COMPLETE,
    // INTERVIEW_BACKGROUND_COLORS_DARK_MODE.PENDING,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.SCHEDULE,
    // INTERVIEW_BACKGROUND_COLORS_DARK_MODE.RESCHEDULE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.COMPLETE,
    INTERVIEW_BACKGROUND_COLORS_DARK_MODE.CANCEL
  ];

  const lightModeColors = [
    // INTERVIEW_COLORS.PENDING,
    INTERVIEW_COLORS.SCHEDULE,
    // INTERVIEW_COLORS.RESCHEDULE,
    INTERVIEW_COLORS.COMPLETE,
    // INTERVIEW_COLORS.PENDING,
    INTERVIEW_COLORS.SCHEDULE,
    // INTERVIEW_COLORS.RESCHEDULE,
    INTERVIEW_COLORS.COMPLETE,
    // INTERVIEW_COLORS.PENDING,
    INTERVIEW_COLORS.SCHEDULE,
    // INTERVIEW_COLORS.RESCHEDULE,
    INTERVIEW_COLORS.COMPLETE,
    // INTERVIEW_COLORS.PENDING,
    INTERVIEW_COLORS.SCHEDULE,
    // INTERVIEW_COLORS.RESCHEDULE,
    INTERVIEW_COLORS.COMPLETE,
    // INTERVIEW_COLORS.PENDING,
    INTERVIEW_COLORS.SCHEDULE,
    // INTERVIEW_COLORS.RESCHEDULE,
    INTERVIEW_COLORS.COMPLETE,
    INTERVIEW_COLORS.CANCEL
  ];

  const statusChartData = {
    labels: generateLabelForChart(),
    datasets: [
      {
        data: DashboardStatusGraphlabels.map((status) => roundStatusCode[status]),
        borderWidth: 0,
        borderRadius: 50,
        barThickness: 20,
        backgroundColor: isDarkMode() ? darkModeColors : lightModeColors,
        datalabels: {
          color: '#FFF'
        }
      },
      {
        borderWidth: 0,
        label: 'Selected',
        borderRadius: 50,
        barThickness: 20,
        backgroundColor: INTERVIEW_COLORS.COMPLETE,
        datalabels: {
          color: '#FFF'
        },
        data: DashboardStatusGraphlabels.map((status) => selectedCount[status])
      },
      {
        borderWidth: 0,
        label: 'Rejected',
        borderRadius: 50,
        barThickness: 20,
        backgroundColor: INTERVIEW_COLORS.CANCEL,
        datalabels: {
          color: '#FFF'
        },
        data: DashboardStatusGraphlabels.map((status) => rejectedCount[status])
      }
    ]
  };

  return (
    <div className="white-box-item full-width">
      <div className="white-box">
        <div className="card-title-block">
          <EqualHeightElement name="title">
            <div className="card-title-wrapper">
              <span className="card-title">Interview status</span>
              <i
                className="custom-target-icon pi pi-info-circle pl-1"
                data-tip={`${
                  filterStatus === 'week'
                    ? "Last week's"
                    : filterStatus === 'month'
                    ? "Last month's"
                    : "Today's"
                } Interviews based on Status`}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </EqualHeightElement>
          <div className="filter-block">
            <Menu
              model={statusFilter}
              popup
              ref={durationFilterRef}
              id="popup_menu"
              appendTo="self"
              className="popup_menu action-popup chartFilterMenu"
            />
            <Menu
              model={hrData}
              popup
              ref={hrFilterRef}
              id="popup_menu"
              appendTo="self"
              className="popup_menu action-popup chartFilterMenu hr-name-list"
            />
            <CustomeTooltip />
            <div className="space-between">
              <Button
                data-tip="Duration Filter"
                type="button"
                onClick={(event) => durationFilterRef.current.toggle(event)}
                aria-controls="popup_menu"
                aria-haspopup
                className="p-button-rounded p-button-text border-none primary-icon-btn filter-btn mr-1">
                <i>
                  <img src={filterIc} className="default-img" alt="filter" />
                  <img src={filterIcDark} className="dark-mode-img" alt="filter" />
                </i>
              </Button>
              <Button
                data-tip="Filter By HR"
                type="button"
                onClick={(event) => hrFilterRef.current.toggle(event)}
                aria-controls="popup_menu"
                aria-haspopup
                className="p-button-rounded p-button-text border-none primary-icon-btn filter-btn">
                <i>
                  <img src={filterIc} className="default-img" alt="filter" />
                  <img src={filterIcDark} className="dark-mode-img" alt="filter" />
                </i>
              </Button>
            </div>
          </div>
        </div>

        <div className="chart-container">
          <div className="chart-figure-wrapper">
            <Chart
              type="bar"
              data={statusChartData}
              options={getBarOptions()}
              plugins={[ChartDataLabels]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
